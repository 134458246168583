import {
  BadgeType,
  Icon,
  Row,
  Space,
  Write,
  Zone,
  toCapitalizeCase,
  useTranslation,
} from '@gimlite/watermelon';
import {
  Blurb,
  BlurbType,
} from '@gimlite/watermelon/components/blurb/blurb.component';
import { useMemo } from 'react';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import {
  ContractBadgesType,
  useContractBadges,
} from '../../common/utils/contract-badges.hook';
import { CredentialMiniGroupType } from '../../components/credential/credential.component';

class Static {
  static statusBadgeColorMap: Record<
    ContractMotoristLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    ACTIVE: 'disabled',
    SUSPENDED: 'warn',
    EXPIRED: 'disabled',
  };
}

export declare namespace ContractMotoristLineType {
  type Props = {
    data: {
      id?: string;
      isStopped?: boolean;
      parkingId?: string;
      firstName: string | null;
      lastName: string | null;
      status: ContractMotoristLineType.Data.Status;
      contractId: string | null;
      carCounter?: [number, number];
      motoristId?: string;
      isInside?: boolean;
      isUpcoming?: boolean;
      credentialsList?: React.ReactElement<CredentialMiniGroupType.Props>;
      apbNext?: boolean;
      classification?: string | null;
      category?: string;
    };

    config?: {
      action?: boolean;
      context?: 'tree' | 'flat';
      icon?: 'solo' | 'pool';
    };
  };

  namespace Data {
    type Status = 'ACTIVE' | 'SUSPENDED' | 'EXPIRED';
  }
}

export const ContractMotoristLine = ({
  data: {
    id,
    firstName,
    lastName,
    status,
    contractId,
    carCounter,
    isStopped,
    parkingId,
    motoristId,
    isInside,
    credentialsList,
    isUpcoming,
    apbNext,
    category,
    classification,
  },
  config: { action = true, context = 'flat', icon = 'pool' } = {},
}: ContractMotoristLineType.Props) => {
  const { t, lang } = useTranslation();

  let identityOptions: Pick<BlurbType.Props['data'], 'subcontain'> = {};
  if (credentialsList) {
    identityOptions = { ...identityOptions, subcontain: credentialsList };
  }

  const states: [ContractBadgesType.Data.State] = useMemo(() => {
    const all: [ContractBadgesType.Data.State] = [status];
    if (isUpcoming) all.push('UPCOMING');
    if (isStopped) all.push('EXPIRED');
    if (isInside) all.push('PRESENT');
    if (isStopped || isUpcoming) all.shift();
    return all;
  }, [isInside, isUpcoming, isStopped]);

  const badges = useContractBadges({ states });

  const counterOver = useMemo(() => {
    if (!carCounter) return false;
    return carCounter[0] > carCounter[1];
  }, [carCounter]);

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [['icon', 'identity', 'car', 'empty']],
        rows: ['1fr'],
        columns: ['min-content', '1fr', '100px', '65px', 'min-content'],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Zone.Area config={{ area: 'icon' }}>
        <Icon
          config={{
            size: 'list',
            type:
              icon === 'pool'
                ? iconGlobalEntity.motoristContractPool
                : iconGlobalEntity.motoristContractSolo,
          }}
        ></Icon>
      </Zone.Area>

      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            ...identityOptions,
            title:
              !firstName && lastName
                ? toCapitalizeCase(lastName)
                : firstName && !lastName
                  ? toCapitalizeCase(firstName)
                  : firstName && lastName
                    ? `${toCapitalizeCase(lastName)} ${toCapitalizeCase(firstName)}`
                    : t('unknown'),
            description: contractId
              ? `${contractId} ${classification ? ` • ${classification}` : ''}`
              : '',
            badge: badges,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'car' }}>
        <Row config={{ horizontal: 'end', vertical: 'center' }}>
          {carCounter ? (
            <>
              <Write
                config={{
                  wrap: false,
                  mode: 'key-small-bold',
                  color: counterOver ? 'error' : 'text',
                }}
                data={{ item: `${carCounter[0]} / ${carCounter[1]}` }}
              />

              <Space
                config={{
                  count: 0.5,
                  way: 'horizontal',
                }}
              />
              <Icon
                config={{
                  type: 'faCarSolid',
                  size: 'xxsmall',
                  color: counterOver ? 'error' : 'text',
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'empty' }}>
        <></>
      </Zone.Area>
    </Zone>
  );
};
