import { gql } from '@apollo/client';

export const childContractsGql = gql`
  query operatorPoolContracts(
    $parkingId: String!
    $contractId: String!
    $status: [String]
    $category: [String]
    $isMotoristIn: Boolean
    $isExpired: Boolean
  ) {
    childrenContracts(
      parkingId: $parkingId
      contractId: $contractId
      status: $status
      category: $category
      isMotoristIn: $isMotoristIn
      isExpired: $isExpired
    ) {
      _id
      category
      ospContractId
      isEnabled
      name1
      name2
      contractCount
      spacesAllocated
      spacesOccupied
      presenceCounter
      stopDate
      startDate
      parkingId
      isInside
      motoristCount
      hasChildren
      freeflag {
        _id
      }
      reference
      motorist {
        _id
        firstName
        lastName
      }
    }
  }
`;
