import { Pagination as PaginationAnt } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Loading } from '../loading/loading.component';
import { Write } from '../write/write.component';
import './pagination.component.scss';

export type PagingInType = { limit: number; count: number; current: number };
export type PagingOutType = { page: number; limit: number };

export declare namespace PaginationClickType {
  type Props = {
    className?: string;
    data: PagingInType;
    handleEvent?: {
      paging?: PaginationClickType.HandleEvent.Paging['function'];
    };
    config?: {
      size?: boolean;
    };
  };

  namespace HandleEvent {
    type Paging = {
      params1: { page: number; limit: number };
      return: void;
      function: (
        paging: PaginationClickType.HandleEvent.Paging['params1'],
      ) => PaginationClickType.HandleEvent.Paging['return'];
    };
  }
}

export const PaginationClick = ({
  className = '',
  handleEvent: { paging } = {},
  config: { size = true } = {},
  data: { current, limit, count },
}: PaginationClickType.Props) => {
  return (
    <div className={`paginationclick ${className}`}>
      <PaginationAnt
        showQuickJumper
        showSizeChanger={size}
        onChange={(page, limit) => paging?.({ limit, page })}
        showTotal={(total) => `${total} lines`}
        defaultCurrent={current}
        current={current}
        defaultPageSize={limit}
        pageSize={limit}
        total={count}
        pageSizeOptions={[10, 20, 30, 50]}
      />
    </div>
  );
};

export declare namespace PaginationScrollType {
  type Props = {
    className?: string;
    data: PagingInType;
    handleEvent?: {
      paging?: PaginationScrollType.HandleEvent.Paging['function'];
      component?: (value: React.ReactNode) => void;
    };
    config: {
      scroll: {
        target: HTMLElement;
        total: number;
        end?: number;
      };
    };
  };

  namespace HandleEvent {
    type Paging = {
      params1: { page: number; limit: number };
      return: void;
      function: (
        paging: PaginationScrollType.HandleEvent.Paging['params1'],
      ) => PaginationScrollType.HandleEvent.Paging['return'];
    };
  }
}

export const PaginationScroll = ({
  className,
  handleEvent: { paging, component } = {},
  config: {
    scroll: { target, total, end = 10 }, //! Le devrait être le offsetHeight du composant envoyé
  },
  data: { limit, count, current },
}: PaginationScrollType.Props) => {
  const [juncture, setJuncture] = useState(false);
  const [awaitNewData, setAwaitNewData] = useState(false);
  const { t, lang } = useTranslation();

  const noMoreData = useMemo(
    () => (
      <Write
        data={{ item: t('watermelon-no-more-data') }}
        config={{
          mode: 'label',
        }}
      />
    ),
    [lang],
  );

  const handleScroll = useCallback((e: any) => {
    const { offsetHeight, scrollHeight, scrollTop } = e.currentTarget;
    const currentScroll = scrollHeight - offsetHeight - scrollTop;
    setJuncture(currentScroll <= end);
  }, []);

  useEffect(() => {
    if (current === 1) target?.addEventListener('scroll', handleScroll);
    return () => target?.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (current === 1) {
      if (total >= count) {
        component?.(noMoreData);
      } else {
        component?.(<></>);
      }
    }

    setAwaitNewData(() => false);
  }, [current]);

  useEffect(() => {
    if (!juncture || awaitNewData) return;

    if (total >= count) {
      component?.(noMoreData);
    } else {
      paging?.({ limit, page: current + 1 });
      setAwaitNewData(() => true);
      component?.(<Loading config={{ size: 'small' }} />);
    }
  }, [juncture]);

  return <div className={`paginationscroll ${className}`}></div>;
};
