import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { MotoristsCreateForm } from '../../../../form/motorists-create/motorists-create.form';
import { CreateSeveralMotoristsContext } from '../../createSeveralMotorists.wizard';

export const MotoristsStep = ({
  context: { motorists },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateSeveralMotoristsContext>) => {
  const { t, lang } = useTranslation();

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['work']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'work' }}>
        <Widget
          config={{
            title: t('createSeveralMotorists'),
            shadow: false,
          }}
        >
          <MotoristsCreateForm
            handleEvent={{
              submit: (data) => submit({ motorists: { created: data } }),
              validate: (isValid) => {
                validate(isValid);
              },
            }}
            config={{
              validIfEmpty: false,
            }}
            data={{
              created: motorists.created,
              defaultValue: {
                sendInvitation: false,
              },
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
