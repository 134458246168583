import { gql } from '@apollo/client';
import { Contract } from '../types/entities/contract';

export type PoolContractsAndSubResponse = {
  list: Array<PoolContractsAndSubEntityResponse>;
  paging: {
    current: number;
    count: number;
    limit: number;
  };
};

export type PoolContractsAndSubEntityResponse = {
  _id: string;
  name1: string;
  name2: string | null;
  isEnabled: boolean;
  ospContractId: string;
  spacesAllocated: number;
  spacesOccupied: number;
  presenceCounter: number;
  contractCount: number;
  productId: string;
  startDate: string;
  stopDate: string | null;
  tenantContracts: Array<{ _id: string }>;
  reference: string | null;
};

export type PoolContractsAndSub = {
  page: number;
  limit: number;
  order: string | null;
  category: Contract['category'] | null;
  parkingId: string | null;
};

export const poolContractsAndSubGQL = gql`
  query poolContractsAndSub(
    $page: Int!
    $limit: Int!
    $order: String
    $category: String
    $parkingId: String
    $tenantCanCreateSubContract: Boolean
  ) {
    poolContractsAndSub(
      page: $page
      limit: $limit
      order: $order
      category: $category
      parkingId: $parkingId
      tenantCanCreateSubContract: $tenantCanCreateSubContract
    ) {
      list {
        _id
        name1
        name2
        isEnabled
        ospContractId
        spacesAllocated
        spacesOccupied
        presenceCounter
        contractCount
        productId
        startDate
        stopDate
        reference
        tenantContracts {
          _id
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
