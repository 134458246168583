import { gql } from '@apollo/client';

export const updateContractGql = gql`
  mutation updateContract($contractId: String!, $input: UpdateContractDto!) {
    updateContract(contractId: $contractId, input: $input) {
      _id
      category
      ospContractId
      startDate
      stopDate
      isEnabled
      contractCount
      presenceCounter
      spacesAllocated
      name1
      name2
      parkingId
      description
      isInside
      reference

      product {
        code
        name
      }

      motorist {
        _id
        firstName
        lastName
        address1
        address2
        zipcode
        city
        state
        country
        phone
        gender
      }

      address {
        address1
        address2
        city
        country
        zipcode
      }

      credentials {
        type
        provider
        value
        description
      }

      poolUsers {
        _id
        firstName
        lastName
        username
      }

      freeflag {
        _id
      }

      rules
    }
  }
`;
