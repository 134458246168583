import { Form, Select, requestGQL, useTranslation } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { childContractsGql } from '../../common/gql/child-contracts.gql';
import { contractWParentsGql } from '../../common/gql/contract-w-parents.gql';
import { rootContractsGql } from '../../common/gql/root-contracts.gql';
import { WsEvents } from '../../common/mapper/ws-event.mapper';
import { WidgetHeaderProps } from '../../common/types/component/widget-header-props';
import { Contract } from '../../common/types/entities/contract';
import { Tree } from '../../components/tree/tree.component';
import { ContractMasterPoolLine } from '../line/contract-master-pool.line';
import { ContractMotoristLine } from '../line/contract-motorist.line';
import { ContractSubPoolLine } from '../line/contract-sub-pool.line';

export type ContractListProps = {
  parkingId: string;
  selectedId?: string;
  onSelect?: (id?: string) => void;
  clicked?: boolean;
} & WidgetHeaderProps;

const leafContractTypes = ['MOTORIST_POOL', 'PERIOD_SUBSCRIPTION'];

const sortContracts = (list: Contract[]) => {
  list.sort((a: Contract, b: Contract) => {
    if (a.motorist && b.motorist) {
      return String(a.motorist.lastName).localeCompare(
        String(b.motorist.lastName),
      );
    }
    return a.category.localeCompare(b.category);
  });
};

export const ContractList = ({
  parkingId,
  widget,
  selectedId,
  onSelect,
  clicked,
}: ContractListProps) => {
  const { t } = useTranslation();
  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <Tree<Contract>
        clicked={clicked}
        nodeRenderer={({
          _id,
          category,
          ospContractId,
          spacesAllocated,
          presenceCounter,
          isEnabled,
          contractCount,
          motoristCount,
          motorist,
          name1,
          name2,
          startDate,
          stopDate,
          parkingId,
          isInside,
          spacesOccupied,
          freeflag,
          hasChildren,
          reference,
        }) => {
          switch (category) {
            case 'MOTORIST_POOL':
            case 'PERIOD_SUBSCRIPTION':
              return (
                // TODO: Voir si on dispose toujours des champs firstName et lastName, etc...
                {
                  component: (
                    <ContractMotoristLine
                      data={{
                        id: _id,
                        firstName: motorist?.firstName || null,
                        lastName: motorist?.lastName || null,
                        contractId: ospContractId,
                        status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                        carCounter: [
                          presenceCounter || 0,
                          spacesAllocated || 1,
                        ],
                        isStopped:
                          stopDate !== undefined &&
                          DateTime.fromISO(stopDate)
                            .diffNow()
                            .as('milliseconds') < 0,
                        isUpcoming:
                          DateTime.fromISO(startDate) > DateTime.now(),
                        parkingId,
                        motoristId: motorist?._id,
                        isInside: presenceCounter! > 0 || false,
                        apbNext: freeflag === null || freeflag === undefined,
                        category,
                        classification: reference,
                      }}
                      config={{
                        action: true,
                        context: 'tree',
                        icon: category === 'MOTORIST_POOL' ? 'pool' : 'solo',
                      }}
                    />
                  ),
                  hasChildren,
                  lastDepth: category === 'MOTORIST_POOL',
                }
              );

            case 'OPERATOR_POOL':
              return {
                component: (
                  <ContractMasterPoolLine
                    data={{
                      id: _id,
                      name1: name1,
                      name2: name2,
                      code: ospContractId,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                      carCounter: [spacesOccupied || 0, spacesAllocated],
                      classification: reference,
                      contractCounter: motoristCount,
                      isStopped:
                        stopDate !== undefined &&
                        DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0,
                      isUpcoming: DateTime.fromISO(startDate) > DateTime.now(),
                      parkingId,
                    }}
                  />
                ),
                hasChildren,
                lastDepth: false,
              };

            case 'TENANT_POOL':
              return {
                component: (
                  <ContractSubPoolLine
                    data={{
                      _id: _id,
                      name1: name1,
                      name2: name2,
                      code: ospContractId,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                      carCounter: [spacesOccupied || 0, spacesAllocated],
                      classification: reference,
                      contractCounter: motoristCount,
                      isStopped:
                        stopDate !== undefined &&
                        DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0,
                      isUpcoming: DateTime.fromISO(startDate) > DateTime.now(),
                      parkingId,
                    }}
                  />
                ),
                hasChildren,
                lastDepth: false,
              };

            default:
              return {
                component: <div>{_id}</div>,
                hasChildren,
                lastDepth: false,
              };
          }
        }}
        filters={[
          <Form.Item
            config={{
              labelLimitation: true,
              label: t('category'),
              name: 'category',
            }}
            data={{ defaultValue: 'ALL' }}
          >
            <Select
              config={{ width: 'xmedium', clear: false, mode: 'single' }}
              data={{
                items: [
                  {
                    label: t('all'),
                    value: 'ALL',
                  },
                  {
                    label: t('single'),
                    value: 'SINGLE',
                  },
                  {
                    label: t('pool'),
                    value: 'POOL',
                  },
                ],
              }}
            />
          </Form.Item>,
          <Form.Item
            config={{
              labelLimitation: true,
              label: t('status'),
              name: 'status',
            }}
            data={{ defaultValue: 'ALL' }}
          >
            <Select
              config={{ width: 'xmedium', clear: false, mode: 'single' }}
              data={{
                items: [
                  {
                    label: t('all'),
                    value: 'ALL',
                  },
                  {
                    label: t('active'),
                    value: 'ACTIVE',
                  },
                  {
                    label: t('suspended'),
                    value: 'SUSPENDED',
                  },
                ],
              }}
            />
          </Form.Item>,
          <Form.Item
            config={{
              labelLimitation: true,
              label: t('validity'),
              name: 'validity',
            }}
            data={{ defaultValue: 'ALL' }}
          >
            <Select
              config={{ width: 'xmedium', clear: false, mode: 'single' }}
              data={{
                items: [
                  {
                    label: t('all'),
                    value: 'ALL',
                  },
                  {
                    label: t('not_expired'),
                    value: 'NOT_EXPIRED',
                  },
                  {
                    label: t('expired'),
                    value: 'EXPIRED',
                  },
                ],
              }}
            />
          </Form.Item>,
          <Form.Item
            config={{
              labelLimitation: true,
              label: t('presence'),
              name: 'presence',
            }}
            data={{ defaultValue: 'ALL' }}
          >
            <Select
              config={{ width: 'xmedium', clear: false, mode: 'single' }}
              data={{
                items: [
                  {
                    label: t('all'),
                    value: 'ALL',
                  },
                  {
                    label: t('present'),
                    value: 'PRESENT',
                  },
                  {
                    label: t('not_present'),
                    value: 'NOT_PRESENT',
                  },
                ],
              }}
            />
          </Form.Item>,
        ]}
        buildFilterSearch={(filters) => {
          if (Object.keys(filters).length === 0) return {};

          let searchFilter = {};
          if (filters.category) {
            switch (filters.category) {
              case 'SINGLE':
                searchFilter = {
                  ...searchFilter,
                  category: ['PERIOD_SUBSCRIPTION'],
                };
                break;

              case 'POOL':
                searchFilter = {
                  ...searchFilter,
                  category: ['OPERATOR_POOL', 'TENANT_POOL', 'MOTORIST_POOL'],
                };
                break;
            }
          }

          if (filters.status && 'ALL' !== filters.status) {
            searchFilter = {
              ...searchFilter,
              status: [filters.status],
            };
          }

          if (filters.presence && 'ALL' !== filters.presence) {
            searchFilter = {
              ...searchFilter,
              isMotoristIn: filters.presence === 'PRESENT',
            };
          }

          if (filters.validity && 'ALL' !== filters.validity) {
            searchFilter = {
              ...searchFilter,
              isExpired: filters.validity === 'EXPIRED',
            };
          }

          return searchFilter;
        }}
        onFilter={() => {}}
        fetchRootNodes={async (searchFilter?: object) => {
          const result: Contract[] = await requestGQL({
            operationName: 'rootContracts',
            gql: rootContractsGql,
            params: {
              parkingId,
              ...searchFilter,
            },
          });

          sortContracts(result);

          return result;
        }}
        fetchChildren={async (id: string, filterQ?: object) => {
          const children = await requestGQL({
            operationName: 'childrenContracts',
            gql: childContractsGql,
            params: {
              parkingId,
              contractId: id,
              ...filterQ,
            },
          });

          sortContracts(children);

          return children;
        }}
        fetchNodeWParents={async (contractId: string) => {
          const contractWParents = await requestGQL({
            operationName: 'contractWParents',
            gql: contractWParentsGql,
            params: {
              parkingId,
              contractId,
            },
          });

          return contractWParents;
        }}
        wsSubscriptions={[
          WsEvents.CONTRACT_CREATED,
          WsEvents.CONTRACT_UPDATED,
          WsEvents.MOTORIST_UPDATED,
          WsEvents.POOLS_CREATED,
        ]}
        isLeaf={(data) => leafContractTypes.includes(data.category)}
        onSelect={onSelect}
        initialFocus={selectedId}
        cacheTtlMs={1000}
        parkingId={parkingId}
      />
    </Widget>
  );
};
