import { gql } from '@apollo/client';

export const rootContractsGql = gql`
  query rootContracts(
    $parkingId: String!
    $status: [String]
    $category: [String]
    $isMotoristIn: Boolean
    $isExpired: Boolean
  ) {
    rootContracts(
      parkingId: $parkingId
      status: $status
      category: $category
      isMotoristIn: $isMotoristIn
      isExpired: $isExpired
    ) {
      _id
      ospContractId
      isEnabled
      name1
      name2
      contractCount
      spacesAllocated
      presenceCounter
      category
      stopDate
      startDate
      parkingId
      isInside
      spacesOccupied
      motoristCount
      reference
      freeflag {
        _id
      }

      motorist {
        _id
        firstName
        lastName
      }
      hasChildren
    }
  }
`;
